// Description: Website path/route management file.
// Author: Tomislav Žabčić-Matić

// Imports:
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Lander from './Containers/Lander/Lander';
import Search from './Containers/Search/Search';
import Login from './Containers/Login/Login';

// Component:
export default class Routes extends Component {
    render() {
        return (
            <Router>
                <Route exact path={'/'} component={Lander}/>
                <Route path={'/search'} component={Search}/>
                <Route exact path={'/login'} component={Login}/>
            </Router>
        );
    }
}
