// Description: Landing page file for Fairy Search
// Author: Tomislav Žabčić-Matić

// Imports:
import React, { PureComponent } from 'react';
import logo from '../../Resources/favicon_white_pink.png';
import './Lander.css';
import * as dd from '../../DeviceManager/DetectDevice';
import { Redirect } from 'react-router-dom';
import Firebase from "../../Firebase/Firebase";

const firebase = new Firebase();

// Component:
export default class Lander extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            user: null,
            deviceType: dd.isMobile(navigator) ? 'Mobile' : 'Desktop',
            searchTerm: '',
            searching: false
        }
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                window.location.href = '/login';
            }
            else {
                if (user.email !== 'test@fairybeta.com') {
                    firebase.auth().signOut()
                        .then(() => { console.log('signed out of invalid account') })
                        .catch(() => { console.log('error signing out of invalid account') });
                }
            }
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSearch = (event) => {
        if (event) {
            event.preventDefault();
        }

        // TODO: implement search redirection
        if (!this.state.searchTerm) {
            return;
        }

        // window.history.pushState({}, '', '/search/' + encodeURIComponent(this.state.searchTerm));
        // window.location.href = '/search/' + encodeURIComponent(this.state.searchTerm);
        this.setState({
            searching: true
        });
    };

    render() {
        if (this.state.searching) {
            return (<Redirect to={'/search/' + encodeURIComponent(this.state.searchTerm)}/>)
        }

        return (<div className={'Lander ' + this.state.deviceType}>
            <div className={'Title'}>
                <span className={'Name'}>fairy{' '}</span>
                <img
                    className={'Logo'}
                    src={logo}
                    alt={'Landing Page Logo'}
                />
            </div>
            <div className={'FormContainer'}>
                <form onSubmit={this.handleSearch}>
                    <input
                        className={'Search'}
                        id={'searchTerm'}
                        type={'text'}
                        value={this.state.searchTerm}
                        placeholder={'Find something near you (i.e. "black dress")'}
                        title={'Search'}
                        onChange={this.handleChange}
                    />
                    <input className={'Hidden'} type={'submit'}/>
                </form>
            </div>
            <div className={'SubmitButton'}>
                <div className={'Centering'} onClick={this.handleSearch}>
                    SEARCH
                </div>
            </div>
        </div>)
    }
}



















