// Description: Login/password protect component
// Author: Tomislav Žabčić-Matić

// Imports:
import React, { PureComponent } from 'react';
import Firebase from "../../Firebase/Firebase";
import './Login.css';

const firebase = new Firebase();

// Component:
export default class Login extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            password: ''
        };
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                window.location.href = '/';
            }
        });
    }

    handleLogin = (event) => {
        if (event) {
            event.preventDefault();
        }

        firebase.auth().signInWithEmailAndPassword('test@fairybeta.com', this.state.password)
            .catch(() => {
                window.alert('The password you entered is incorrect. Please try again!');
            });
    };

    handlePWChange = (event) => {
        this.setState({
            password: event.target.value
        });
    };

    render() {
        return (<div className={'Login'}>
            <div className={'Title'}>Log In</div>
            <div className={'FormContainer'}>
                <form onSubmit={this.handleLogin}>
                    <input
                        className={'Password'}
                        type={'password'}
                        placeholder={'Password'}
                        onChange={this.handlePWChange}
                        value={this.state.password}
                        id={'password'}
                        title={'Password'}
                    />
                    <input type={'submit'} style={{ display: 'none' }}/>
                </form>
            </div>
            <div className={'SubmitButton'}>
                <div className={'Centering'} onClick={this.handleLogin}>LOG IN</div>
            </div>
        </div>);
    }
}



















