import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import * as fc from "../firebaseConfig";

app.initializeApp(fc.config);

export default class Firebase {
    constructor() {
        this.auth = app.auth;
        this.database = app.database;
        this.firestore = app.firestore;
    }
}
