// Description: Search module for Fairy local search
// Author: Tomislav Žabčić-Matić

// Imports:
import React, { PureComponent } from 'react';
import './Search.css';
import { Helmet } from "react-helmet";
import { GoogleMap, LoadScript, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import ProductCard from "./ProductCard";
import ProductDetail from "./ProductDetail";
import * as algoliasearch from 'algoliasearch';
import * as axios from 'axios';
import * as gmc from '../../googleMapsConfig';
import * as ac from '../../algoliaConfig';
import * as dd from "../../DeviceManager/DetectDevice";
import { milesToMeters } from '../../Helpers/Distance'
import logo from '../../Resources/favicon_white_pink.png';
import Firebase from "../../Firebase/Firebase";

const firebase = new Firebase();
const client = algoliasearch(ac.config.projectName, ac.config.apiKey);

// Component:
export default class Search extends PureComponent {
    constructor(props) {
        super(props);

        this.gmLibraries = ['places'];

        this.state = {
            loggedIn: false,
            user: null,
            deviceType: dd.isMobile(navigator) ? 'Mobile' : 'Desktop',
            searchTerm: '',
            permanentSearchTerm: '',
            loaded: false,
            locationSearchTerm: '',
            locationAddress: 'San Francisco, CA',
            locationGeoloc: {
                lat: 37.7749,
                lng: -122.4194
            },
            searchRadius: 5,
            storeResults: [],
            itemResults: [],
            rawItemResults: [],
            productInFocus: false,
            focusedProduct: {}
        }
    }

    async componentDidMount() {
        window.addEventListener('popstate', async () => {
            await this.setState({
                permanentSearchTerm: (window.location.href.includes('search/'))
                    ? decodeURIComponent(window.location.href.split('search/')[1]) : '',
                searchTerm: (window.location.href.includes('search/'))
                    ? decodeURIComponent(window.location.href.split('search/')[1]) : ''
            });

            this.getItems();
        });

        await firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                window.location.href = '/login';
            }
            else {
                if (user.email !== 'test@fairybeta.com') {
                    firebase.auth().signOut()
                        .then(() => { console.log('signed out of invalid account') })
                        .catch(() => { console.log('error signing out of invalid account') });
                }
            }
        });

        await this.setState(() => {
            return ({
                permanentSearchTerm: (window.location.href.includes('search/'))
                    ? decodeURIComponent(window.location.href.split('search/')[1]) : '',
                searchTerm: (window.location.href.includes('search/'))
                    ? decodeURIComponent(window.location.href.split('search/')[1]) : ''
            });
        });

        if (navigator.geolocation) {
            console.log('YES');

            navigator.geolocation.getCurrentPosition(async (position) => {
                let formatted_address = '';
                await axios.get(
                    'https://maps.googleapis.com/maps/api/geocode/json?latlng='
                    + position.coords.latitude + ',' + position.coords.longitude
                    + '&key=' + gmc.geocodingApiKey
                )
                    .then((response) => {
                        formatted_address = response.data.results[0].formatted_address;
                    })
                    .catch(() => {
                        window.alert('Something went wrong getting your location. Please try setting it manually!')
                    });

                await this.setState(() => {
                    return ({
                        locationSearchTerm: formatted_address,
                        locationAddress: formatted_address,
                        locationGeoloc: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    })
                });

                this.getItems(null, true);

                this.setState(() => { return ({ loaded: true }); });
            });
        }
        else {
            console.log('NO');

            await this.setState(() => {
                return ({ locationSearchTerm: 'San Francisco, CA' });
            });

            this.getItems(null, true);

            this.setState(() => { return ({ loaded: true }); });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('popstate');
    }

    handleSearchChange = (event) => {
        this.setState({
            searchTerm: event.target.value
        })
    };

    handleLocationSearchChange = (event) => {
        this.setState({
            locationSearchTerm: event.target.value
        });
    };

    handleSearchRadiusChange = (event) => {
        const filteredForLetters = event.target.value.split('')
            .filter((letter) => {
                return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].includes(letter);
            })
            .join('');

        const fullyFiltered = filteredForLetters
            .slice(0, filteredForLetters.split('.').length === 3
                ? filteredForLetters.length - 1 : filteredForLetters.length);

        this.setState({
            searchRadius: fullyFiltered
        });
    };

    setLocation = async (location) => {
        // const newUserData = this.state.user;
        // newUserData.locationName = location.name;
        // newUserData.locationAddress = location.formatted_address;
        // newUserData.locationGeoloc = {
        //     lat: location.geometry.location.lat(),
        //     lng: location.geometry.location.lng()
        // };
        //
        // firebase.firestore().collection('Shoppers').doc(this.state.user.username).set(newUserData)
        //     .then(() => {
        //         this.setState(() => {
        //             return ({ user: newUserData, locationSearchTerm: location.formatted_address });
        //         });
        //     })
        //     .catch(() => {
        //         window.alert('Something went wrong setting the new address. Please try again!');
        //     })

        await this.setState(() => {
            return ({
                locationSearchTerm: location.formatted_address,
                locationAddress: location.formatted_address,
                locationGeoloc: {
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng()
                }
            });
        });

        this.getItems();
    };

    handleSearch = (event) => {
        if (event) {
            event.preventDefault();
        }

        window.history.pushState({}, '', '/search/' + encodeURIComponent(this.state.searchTerm));

        this.setState({
            permanentSearchTerm: this.state.searchTerm
        });

        this.getItems();
    };

    getItems = () => {
        // if (event) {
        //     event.preventDefault();
        // }

        // console.log('getItems');

        const index = client.initIndex(ac.indices.product);

        // console.log(milesToMeters(Number(this.state.searchRadius)));


        // window.history.pushState({}, '', '/search/' + encodeURIComponent(this.state.searchTerm));
        // window.location.href = '/search/' + encodeURIComponent(this.state.searchTerm);

        index.search({
            query: this.state.searchTerm,
            aroundLatLng: String(this.state.locationGeoloc.lat) + ',' + String(this.state.locationGeoloc.lng),
            aroundRadius: milesToMeters(Number(this.state.searchRadius)),
            getRankingInfo: true,
            hitsPerPage: 1000
        }, (err, results) => {
            if (err) {
                console.log(err);
                window.alert('Something went wrong, please try again or try refreshing the page!');
                return;
            }

            console.log('searched');

            console.log(results);

            let stores = [];
            const items = {};
            results.hits.map((hit) => {
                if (!items.hasOwnProperty(hit.store_id)) {
                    stores = stores.concat([{
                        store_id: hit.store_id,
                        storeGeoloc: hit._geoloc,
                        distance: hit._rankingInfo.geoDistance
                    }]);
                    items[hit.store_id] = [{...hit, distance: hit._rankingInfo.geoDistance}];
                }
                else {
                    items[hit.store_id] = items[hit.store_id].concat([{
                        ...hit, distance: hit._rankingInfo.geoDistance}]);
                }

                return null;
            });

            this.setState(() => {
                return ({
                    storeResults: stores,
                    itemResults: items,
                    rawItemResults: this.state.searchTerm ? results.hits.map((hit) => {
                        return ({
                            ...hit,
                            distance: hit._rankingInfo.geoDistance
                        });
                    }) : []
                });
            });
        });
    };

    toggleProductInFocus = (product) => {
        console.log('toggle');
        this.setState((state) => {
            return ({
                productInFocus: !state.productInFocus,
                focusedProduct: product
            });
        });
    };

    render() {
        return (<div className={'Search ' + this.state.deviceType}>
            <Helmet>
                <meta charSet={'utf-8'}/>
                <title>{this.state.permanentSearchTerm + ' | Fairy Search'}</title>
                <meta name={'description'} content={'Fairy Search - find "' + this.state.permanentSearchTerm + '" near me'}/>
            </Helmet>
            <LoadScript
                id={'ScriptLoader'}
                googleMapsApiKey={gmc.apiKey}
                libraries={this.gmLibraries}
            >
                <div className={'Toolbar'}>
                    <div className={'ToolbarContent'}>
                        <img className={'Logo'} src={logo} alt={'Fairy Logo'}/>
                        <form className={'SearchForm'} onSubmit={this.handleSearch}>
                            <input
                                type={'text'}
                                className={'SearchBar'}
                                value={this.state.searchTerm}
                                onChange={this.handleSearchChange}
                                placeholder={'Find something near you (i.e. "black dress")'}
                            />
                            <input type={'submit'} style={{ display: 'none' }}/>
                        </form>
                        <div className={'MapSearchContainer'}>
                            <GoogleMap
                                id={'BaseMap'}
                                mapContainerStyle={{
                                    height: '100%',
                                    width: '100%',
                                    boxShadow: '0 0 3px 1px #CCB4F3',
                                    WebkitBoxShadow: '0 0 3px 1px #CCB4F3',
                                    MozBoxShadow: '0 0 3px 1px #000',
                                    marginBottom: '1vh',
                                    borderRadius: '5px'
                                }}
                            >
                                <StandaloneSearchBox
                                    onLoad={ (ref) => { this.searchBox = ref; } }
                                    onPlacesChanged={
                                        () => {
                                            console.log(this.searchBox.getPlaces()[0]);
                                            this.setLocation(this.searchBox.getPlaces()[0]);
                                        }
                                    }
                                >
                                    <input
                                        type={'text'}
                                        className={'SearchBar'}
                                        value={this.state.locationSearchTerm}
                                        placeholder={'Enter your location'}
                                        style={{
                                            // boxSizing: 'border-box',
                                            border: '1px solid transparent',
                                            width: '100%',
                                            height: '100%',
                                            padding: '0 5px',
                                            borderRadius: '3px',
                                            fontSize: '2vh',
                                            outline: 'none',
                                            textOverflow: 'ellipses',
                                            position: 'absolute',
                                            backgroundColor: '#E5E5E5'
                                        }}
                                        onChange={this.handleLocationSearchChange}
                                    />
                                </StandaloneSearchBox>
                            </GoogleMap>
                        </div>
                        <form className={'RadiusForm'} onSubmit={this.getItems}>
                            <input
                                type={'text'}
                                className={'Radius'}
                                value={this.state.searchRadius}
                                onChange={this.handleSearchRadiusChange}
                                placeholder={'Radius (mi)'}
                            />
                            <input type={'submit'} style={{ display: 'none' }}/>
                        </form>
                        <span className={'MilesSuffix'}>(miles)</span>
                    </div>
                </div>
                <div className={'Main'}>
                    <div className={'SearchResults'}>
                        {this.state.rawItemResults.length ? (<div className={'SRContainer2'}>
                            {this.state.rawItemResults.sort((a, b) => { return (a.distance - b.distance); })
                                .map((item, index) => {
                                    return (<ProductCard
                                        key={index}
                                        images={
                                            // item.images
                                            (item.image_list === item.image_detail)
                                                ? ([item.image_list])
                                                : ([item.image_list, item.image_detail])
                                        }
                                        name={decodeURIComponent(item.name)}
                                        // regPrice={item.regPrice}
                                        regPrice={item.price}
                                        // storeLogo={item.storeLogo}
                                        storeLogo={item.brand_logo}
                                        onProductFocus={this.toggleProductInFocus}
                                        distance={item.distance}
                                        geoloc={item._geoloc}
                                        storeName={item.store_id}
                                    />)
                                })}
                        </div>) : (<div className={'SRContainer'}>
                            {this.state.storeResults.sort((a, b) => { return (a.distance - b.distance); })
                                .map((store, storeIndex) => {
                                    return (<div className={'StoreRow'} key={storeIndex}>
                                        <div className={'StoreName'}>{store.store_id}</div>
                                        <div className={'Items'}>
                                            {this.state.itemResults[store.store_id].map((item, itemIndex) => {
                                                return (<ProductCard
                                                    key={itemIndex}
                                                    images={
                                                        // item.images
                                                        (item.image_list === item.image_detail)
                                                            ? ([item.image_list])
                                                            : ([item.image_list, item.image_detail])
                                                    }
                                                    name={decodeURIComponent(item.name)}
                                                    // regPrice={item.regPrice}
                                                    regPrice={item.price}
                                                    // storeLogo={item.storeLogo}
                                                    storeLogo={item.brand_logo}
                                                    onProductFocus={this.toggleProductInFocus}
                                                    distance={item.distance}
                                                    geoloc={item._geoloc}
                                                    storeName={item.store_id}
                                                />);
                                            })}
                                        </div>
                                    </div>)
                                })}
                        </div>)}
                    </div>
                    <div className={'ResultsMap'}>
                        <GoogleMap
                            id={'BaseMap2'}
                            mapContainerStyle={{
                                height: '100%',
                                width: '100%',
                            }}
                            zoom={12}
                            center={this.state.locationGeoloc}
                            // options={{ styles: exampleMapStyles }}
                        >
                            {(() => {
                                if (!this.state.productInFocus) {
                                    return (this.state.storeResults.map((store, index) => {
                                        return (<Marker
                                            icon={'https://vectr.com/tome215/amvvUoulQ.svg?width=15&height=15&select=amvvUoulQpage0'}
                                            key={index}
                                            position={store.storeGeoloc}
                                        />)
                                    }));
                                }
                                else {
                                    // console.log(this.state.focusedProduct.geoloc);
                                    return (<Marker
                                        icon={'https://vectr.com/tome215/amvvUoulQ.svg?width=15&height=15&select=amvvUoulQpage0'}
                                        position={this.state.focusedProduct.geoloc}
                                    />);
                                }
                            })()}
                            <Marker
                                icon={'https://vectr.com/tome215/a2pKK4zW3.svg?width=30&height=30&select=a2pKK4zW3page0'}
                                position={this.state.locationGeoloc}
                            />
                        </GoogleMap>

                    </div>
                    <div className={'SearchFocus' + (this.state.focusedProduct.name ? ' InFocus' : '')}>
                        <div className={'SFContainer'}>
                            <ProductDetail
                                name={this.state.focusedProduct.name}
                                images={this.state.focusedProduct.images}
                                regPrice={this.state.focusedProduct.regPrice}
                                distance={this.state.focusedProduct.distance}
                                back={() => {this.toggleProductInFocus({})}}
                                storeName={this.state.focusedProduct.storeName}
                                geoloc={this.state.focusedProduct.geoloc}
                            />
                        </div>
                    </div>
                </div>
            </LoadScript>
        </div>)
    }
}



















