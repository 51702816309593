module.exports.config = {
    projectName: 'JU7QGA2NAN',
    apiKey: '35a5d416bc9a67b3bdb812b0b4a81ae1'
};

module.exports.indices = {
    // store: 'dev_Shops_Test',
    store: 'dev_STORE',
    // product: 'dev_Products_Test',
    product: 'dev_PRODUCT'
};
