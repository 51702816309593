module.exports = {
    config: {
        apiKey: "AIzaSyBaWdXDD5q1sHQUeN0hswkmr6UI-C3dVB8",
        authDomain: "fiona-3b15f.firebaseapp.com",
        databaseURL: "https://fiona-3b15f.firebaseio.com",
        projectId: "fiona-3b15f",
        storageBucket: "fiona-3b15f.appspot.com",
        messagingSenderId: "635017175945",
        appId: "1:635017175945:web:31dc0a1a419aa940"
    }
};
