// Description: Product display class for use in search results
// Author: Tomislav Žabčić-Matić

// Imports:
import React, { PureComponent } from 'react';
// import './ProductCard.css';

// Component:
export default class ProductCard extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            curImgIndex: 0
        };
    }

    shiftLeft = () => {
        this.setState((state) => {
            return ({
                curImgIndex: (state.curImgIndex - 1 < 0)
                    ? (state.curImgIndex - 1 + this.props.images.length) : (state.curImgIndex - 1)
            });
        });
    };

    shiftRight = () => {
        this.setState((state) => {
            return ({
                curImgIndex: (state.curImgIndex + 1)%this.props.images.length
            });
        });
    };

    focusOnProduct = (event) => {
        if (event.target.className === 'LeftArrow' || event.target.className === 'RightArrow') {
            return;
        }

        this.props.onProductFocus({
            name: this.props.name,
            images: this.props.images,
            regPrice: this.props.regPrice,
            storeName: this.props.storeName,
            storeLogo: this.props.storeLogo,
            distance: this.props.distance,
            geoloc: this.props.geoloc
        });
    };

    render() {
        return (<div className={'ProductCard'}>
            <div className={'Container'}>
                <div className={'ImageContainer'}>
                    <img
                        className={'ProductImage'}
                        src={(this.props.images[this.state.curImgIndex]).includes('http')
                            ? (this.props.images[this.state.curImgIndex])
                            : ('https://' + this.props.images[this.state.curImgIndex])}
                        alt={this.props.name}
                        onClick={this.focusOnProduct}
                    />
                    <div className={'Arrows' + ((this.props.images.length - 1) ? '' : ' Hidden')} onClick={this.focusOnProduct}>
                        <div className={'ArrowContainer'}>
                            <div className={'LeftArrow'} onClick={this.shiftLeft}>{'<'}</div>
                            <div className={'RightArrow'} onClick={this.shiftRight}>{'>'}</div>
                        </div>
                    </div>
                </div>
                <div className={'ProductInfo'} onClick={this.focusOnProduct}>
                    <div
                        className={'Text'}
                    >
                        <span className={'ProductName'}>{this.props.name}</span>
                        <span className={'ProductPrice'}>
                            {/*{'$' + String(this.props.regPrice).slice(0, -2) + '.' + String(this.props.regPrice).slice(-2)}*/}
                            {'$' + Number(this.props.regPrice).toFixed(2)}
                        </span>
                    </div>
                    <div className={'StoreLogo'}>
                        <img
                            className={'Logo'}
                            src={this.props.storeLogo}
                            alt={this.props.storeName + ' Logo'}
                        />
                    </div>
                </div>
            </div>
        </div>);
    }
}
