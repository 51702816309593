// Description: Detail view version of a product card - contains selectors for sizes and variants
// Author: Tomislav Žabčić-Matić

// Imports:
import React, { PureComponent } from 'react';
// import './ProductDetail.css';
import Firebase from "../../Firebase/Firebase";
import { metersToMiles } from '../../Helpers/Distance';
// import * as twilio from 'twilio';
import lightning from '../../Resources/lightning_pink.png'
import * as axios from 'axios';
import * as gmc from '../../googleMapsConfig';
import * as tc from '../../twilioConfig';

// const client = new twilio(tc.accountSID, tc.authToken);

const firebase = new Firebase();

// Component:
export default class ProductDetail extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            curImgIndex: 0,
            address: '',
            showReserveForm: false,
            reserveName: '',
            reservePhone: '',
            reserveSuccess: false,
            reserveResultMessage: ''
        };

        console.log(props.geoloc);
    }

    toggleReserveForm = () => {
        this.setState((state) => {
            return ({
                showReserveForm: !state.showReserveForm,
                reserveName: '',
                reservePhone: '',
                reserveSuccess: false,
                reserveResultMessage: ''
            });
        });
    };

    handleReserveNameChange = (event) => {
        this.setState({
            reserveName: event.target.value
        });
    };

    handleReservePhoneChange = (event) => {
        const filteredForLetters = event.target.value.split('')
            .filter((letter) => {
                return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(letter);
            })
            .join('');

        const lengthAtMost10 = (filteredForLetters.length <= 10) ? filteredForLetters : filteredForLetters.slice(0, 10);

        this.setState({
            reservePhone: lengthAtMost10
        });
    };

    reserve = (event) => {
        if (event) {
            event.preventDefault();
        }

        firebase.firestore().collection('Reservations').add({
            user: {
                reserveName: this.state.reserveName,
                reservePhone: this.state.reservePhone
            },
            product: {
                name: this.props.name,
                storeName: this.props.storeName,
                address: this.state.address
            }
        })
            .then(() => {
                this.setState(() => {
                    return ({
                        reserveResultMessage: 'Thank you for requesting to reserve this item!' +
                            ' We will be in touch with you shortly regarding its availability!',
                        reserveSuccess: true,
                        showReserveForm: false
                    });
                });
            })
            .catch(() => {
                this.setState(() => {
                    return ({
                        reserveResultMessage: 'Something went wrong, please try again!',
                        reserveSuccess: false,
                        showReserveForm: false
                    });
                });
            });

        // client.messages.create({
        //     body: 'The item "' + this.props.name + '" was just requested by ' + this.state.reserveName + "!"
        //         + ' They can be reached at: (' + this.state.reservePhone.slice(0, 3) + ')-'
        //         + this.state.reservePhone.slice(3, 6) + '-' + this.state.reservePhone.slice(6, 10) + ' !'
        //         + ' This item can be found at ' + this.props.storeName + ', located at: ' + this.state.address + ' !'
        // }).then((message) => {
        //     console.log(message.sid);
        //     this.setState(() => {
        //         return ({
        //             reserveSuccess: true,
        //             reserveResultMessage: ''
        //         });
        //     });
        // }).catch((err) => {
        //     console.log(err);
        //     this.setState(() => {
        //         return ({
        //             reserveSuccess: false,
        //             reserveResultMessage: 'Something went wrong, please try again!'
        //         });
        //     });
        // });


    };

    render() {
        // const refsList = this.props.images.reduce((acc, image, index) => {
        //     acc[index] = createRef();
        //     return acc;
        // });
        //
        // const scrollToImage = (id) =>
        //     refsList[id].current.scrollIntoView({
        //         behavior: 'smooth',
        //         block: 'start',
        //     });

        if (!this.props.name) {
            return (<div className={'ProductDetail'}/>)
        }

        if (this.props.geoloc) {
            axios.get(
                'https://maps.googleapis.com/maps/api/geocode/json?latlng='
                + this.props.geoloc.lat + ',' + this.props.geoloc.lng
                + '&key=' + gmc.geocodingApiKey
            )
                .then((response) => {
                    this.setState(() => { return ({ address: response.data.results[0].formatted_address }); });
                })
                .catch(() => {
                    window.alert('Something went wrong getting the store\'s location. Please try setting it manually!')
                });
        }

        return (<div className={'ProductDetail'}>
            <div className={'Header'}><img
                className={'BackArrow'}
                src={require('../../Resources/back_arrow_pink.png')}
                alt={'Back Arrow'}
                onClick={this.props.back}
            /></div>
            <div className={'PDMain'}>
                <div className={'BasicInfo'}>
                    <div className={'ProductImages'}>
                        {/*<div className={'ImageList'}>*/}
                        {/*    {this.props.images.map((image, index) => {*/}
                        {/*        return (<div*/}
                        {/*            className={'ImageContainer'}*/}
                        {/*            ref={refsList[index]}*/}
                        {/*        >*/}
                        {/*            <img*/}
                        {/*                className={'MiniImage'}*/}
                        {/*                src={image}*/}
                        {/*                alt={''}*/}
                        {/*            />*/}
                        {/*        </div>)*/}
                        {/*    })}*/}
                        {/*</div>*/}
                        <div className={'ImageFocus'}><img
                            className={'FocusedImage'}
                            src={this.props.images[0]}
                            alt={this.props.name + ' Image ' + (this.state.curImgIndex + 1)}
                        /></div>
                    </div>
                    <div className={'ProductInfo'}>
                        <div className={'Title'}>{this.props.name}</div>
                        <div className={'PriceAndDist'}>
                            <div className={'Price'}>
                                {/*{'$' + String(this.props.regPrice).slice(0, -2) + '.' + String(this.props.regPrice).slice(-2)}*/}
                                {'$' + Number(this.props.regPrice).toFixed(2)}
                            </div>
                            <img
                                className={'Lightning'}
                                src={lightning}
                                alt={'Lightning Icon'}
                            />
                            <div className={'Distance'}>{metersToMiles(this.props.distance).toFixed(1) + ' miles away'}</div>
                        </div>
                        <div className={'Store'}>
                            <div className={'StoreName'}>{this.props.storeName}</div>
                            <div className={'StoreAddress'}>{this.state.address}</div>
                        </div>
                        {(!this.state.showReserveForm) ? (<div>
                            <div className={'Reserve'}>
                                <div className={'Centering'} onClick={this.toggleReserveForm}>
                                    RESERVE
                                </div>
                            </div>
                            {(this.state.reserveResultMessage) ? (<div
                                className={'ReserveMessage ' + (this.state.reserveSuccess ? 'Success' : 'Error')}
                            >
                                {this.state.reserveResultMessage}
                            </div>) : (<div/>)}
                        </div>) : (<form className={'ReserveForm'} onSubmit={this.reserve}>
                            <input
                                id={'reserveName'}
                                type={'text'}
                                value={this.state.reserveName}
                                onChange={this.handleReserveNameChange}
                                placeholder={'Full Name'}
                            />
                            <input
                                id={'reservePhone'}
                                type={'text'}
                                value={this.state.reservePhone}
                                onChange={this.handleReservePhoneChange}
                                placeholder={'Phone'}
                            />
                            <div className={'SubmitButton'}>
                                <div className={'SBCentering'} onClick={this.reserve}>
                                    SUBMIT
                                </div>
                            </div>
                            <div className={'CancelButton'}>
                                <div className={'CBCentering'} onClick={this.toggleReserveForm}>
                                    CANCEL
                                </div>
                            </div>
                        </form>)}
                    </div>
                </div>
            </div>
        </div>)
    }
}
